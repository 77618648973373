import React from 'react';
import { graphql, Helmet } from 'gatsby';
import Page from './Page.react';
import SEO from '../seo';
import { HOME_PAGE_ATTRIBUTES, pageProcessor } from '../../queries/queryUtils.react';

const PAGE_ATTRIBUTES = HOME_PAGE_ATTRIBUTES;
const AutoPage = ({ data, props, className, tbrMenu }) => {
	// const siteTitle = get(this.props, 'data.site.siteMetadata.title')

	const pageObject = pageProcessor(data.contentfulPage, PAGE_ATTRIBUTES);
	return (
		// <pre>{JSON.stringify(instaFeed, null, 4)}</pre>

		<>
			<SEO
				description={data.contentfulPage.seoDescription.seoDescription}
				title={data.contentfulPage.seoTitle}
				lang={'en'}
			/>
			<Page
				{...props}
				className={className}
				page={data.contentfulPage.slug}
				content={pageObject}
				frame={pageObject.frame}
			/>
		</>
	);
};

export default AutoPage;

export const pageQuery = graphql`
	query contentfulPageQuery($slug: String!) {
		contentfulPage(slug: { eq: $slug }) {
			slug
			frame
			...PageDev
			...Helmet
			...HeroParts
			...HeroImages
			...Blocks

			id
			secondaryPageBlock {
				childMarkdownRemark {
					html
				}
			}
			secondaryBlockTitle
			secondaryBlockColumns
		}
	}
`;
